@tailwind base;
@tailwind components;
@tailwind utilities;

ol {
  list-style: decimal;
  font-size: small;
  margin-left: 18px;
}
ul {
  font-size: small;
  list-style: none;
  margin-left: 0px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.rdw-editor-wrapper a {
  text-decoration: underline;
}

.rdw-link-modal {
  width: auto;
  height: auto;
  border-radius: 16px;
}
.rdw-link-modal-btn {
  border-radius: 16px;
}
