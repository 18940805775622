/* Override the background color of the calendar */
.react-datepicker-popper {
  background-color: white; /* Change to your desired color */
  border: none;
}

/* Override the color of the calendar's border */
.react-datepicker-wrapper {
  border: none !important;
}

/* Override the background color of the calendar header */
.react-datepicker__header {
  background-color: white; /* Change to your desired color */
  border: none;
}

/* Override the color of the weekdays */
.react-datepicker__day-name {
  color: #333333; /* Change to your desired color */
}

/* Override the color of the selected date */
.react-datepicker__day--selected {
  background-color: #5e91ff; /* Change to your desired color */
  color: #ffffff; /* Change to your desired color */
  border-radius: 8px;
}

/* Override the color of the hovered date */
.react-datepicker__day:hover {
  background-color: #5e91ff; /* Change to your desired color */
  border-radius: 8px;
  color: white;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #5e91ff;
  border-radius: 8px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: #5e91ff;
  color: white;
  border-radius: 8px;
}
.react-datepicker__time-container {
  width: 100px;
}
